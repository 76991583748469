<template>
	<section class="fullpage fp-index-2024 __fp-light">
		<video autoplay muted loop id="mainVideo">
			<source src="~@/assets/img/main/main2024_video.webm" type="video/webm" />
			<source src="~@/assets/img/main/main2024_video.mp4" type="video/mp4" />
		</video>
		<div class="fp-content container">
			<div class="fp-index-slogan">
				<p class="fp-index-slogan__desc"><span>소상공인 온라인 진출의 시작부터 성공까지!</span></p>
				<h2 class="fp-index-slogan__title">소상공인 온라인 <strong class="color-orange">판로지원 플랫폼</strong></h2>
			</div>
			<main-index-noti />

			<div class="fp-light-box">
				<div class="fp-index-steps">
					<ol>
						<li :class="{ 'is-current': stepIndex === 0 }">
							<button type="button" class="fp-index-steps__item" @click.prevent="gotoSwiper(0)">
								<em>STEP 1</em>
								<strong>정보제공</strong>
							</button>
						</li>
						<li :class="{ 'is-current': stepIndex === 1 }">
							<button type="button" class="fp-index-steps__item" @click.prevent="gotoSwiper(3)" :class="{ 'bounce-ani': stepIndex === 0 }">
								<em>STEP 2</em>
								<strong>진출지원</strong>
							</button>
						</li>
						<li :class="{ 'is-current': stepIndex === 2 }">
							<button type="button" class="fp-index-steps__item" @click.prevent="gotoSwiper(6)" :class="{ 'bounce-ani': stepIndex === 1 }">
								<em>STEP 3</em>
								<strong>실전지원</strong>
							</button>
						</li>
					</ol>
				</div>
				<swiper ref="swiperIndex" class="swiper" :options="swiperOption" @slideChange="onSwiperChange">
					<swiper-slide v-for="(item, index) in swiperList" :key="index">
						<div class="fp-index-card">
							<div class="fp-index-card__header">
								<strong>{{ item.cate }}</strong>
								<h3 class="fp-index-card__title">{{ item.title }}</h3>
							</div>
							<div class="fp-index-card__content" v-html="item.desc"></div>
							<div class="fp-index-card__footer">
								<router-link :to="item.link" type="button" class="button-default is-small is-secondary is-rounded">확인하기</router-link>
							</div>
						</div>
					</swiper-slide>
				</swiper>
				<!-- 20240516 : 표준 swiper control -->
				<swiper-controls :swiper="thisSwiper" :auto="true"></swiper-controls>
			</div>
		</div>
	</section>
</template>

<script>
import store from '@/store';
import { mapGetters } from 'vuex';
import SwiperControls from '@/components/common/SwiperControls';
import MainIndexNoti from './MainIndexNoti';

export default {
	name: 'MainIndex',
	components: { SwiperControls, MainIndexNoti },
	props: {
		// searchData: Function,
	},
	data: () => ({
		thisSwiper: null,
		activeIndex: 0,
		stepIndex: 0,
		swiperList: [
			{ groupID: 0, cate: 'MARKET TV', title: '판로TV', desc: '소상공인 <strong>디지털 전환</strong> 성공 사례부터 <strong>유통사 진출</strong>까지 소상공인에게 유익한 <strong>판로정보</strong>를 제공합니다.', link: '/broadcast' },
			{ groupID: 0, cate: 'INFORMATION', title: '지원사업 동향', desc: '소상공인을 위한 <strong>다양한 지원사업</strong> 및 <strong>정책동향</strong>을 제공합니다.', link: '/sentcont/1202001/list' },
			{ groupID: 0, cate: 'ONFAN NEWS', title: '소상공인 뉴스', desc: '최신 <strong>온라인 트렌드</strong> 및 <strong>시장정보</strong>를 파악할 수 있는 뉴스레터를 제공합니다.', link: '/sentcont/home' },

			{ groupID: 1, cate: 'PROGRAMS', title: '지원사업 모아보기', desc: '소상공인을 위한 다양한 <strong>정부지원사업</strong>을 한눈에 확인 할 수 있습니다.', link: '/supportbiz' },
			{ groupID: 1, cate: 'PROGRAMS', title: '지원사업 알아보기', desc: '소상공인 <strong>지원사업 정보</strong>, <strong>성공사례</strong> 및 산출물을 확인 할 수 있습니다.', link: '/supportbiz/intrd' },
			{ groupID: 1, cate: 'e-LEARNING', title: 'e러닝', desc: '온라인 역량 강화를 희망하는 <strong>소상공인</strong>을 위한 <strong>교육</strong>을 제공합니다.', link: '/education' },

			{ groupID: 2, cate: 'SODAM SQUARE', title: '소담스퀘어', desc: '소상공인을 위한 <strong>전국</strong> 거점별 <strong>촬영 스튜디오</strong> 및 다양한 지원 프로그램을 <strong>무료</strong>로 이용할 수 있습니다. ', link: '/playground2?ctgrCode=1012001' },
			{ groupID: 2, cate: 'LIVE STUDIO', title: '라이브스튜디오', desc: '소상공인을 위한 컨셉 <strong>스튜디오</strong> 및 전문 <strong>촬영 장비</strong>를 <strong>무료</strong>로 이용할 수 있습니다.', link: '/studio' },
			{ groupID: 2, cate: 'SODAM STORE', title: '소담상회', desc: '소상공인의 디지털 전환을 위한 <strong>소상공인 전용 O2O 플래그십 스토어</strong>입니다.  ', link: '/sodam' },
			// { groupID: 2, cate: 'SODAM SQUARE', title: '소담스퀘어', desc: '소상공인을 위한 <strong>전국</strong> 거점별 <strong>촬영 스튜디오</strong> 및 다양한 지원 프로그램을 <strong>무료</strong>로 이용할 수 있습니다. ', link: '/space' },
			// { groupID: 2, cate: 'LIVE STUDIO', title: '라이브스튜디오', desc: '소상공인을 위한 컨셉 <strong>스튜디오</strong> 및 전문 <strong>촬영 장비</strong>를 <strong>무료</strong>로 이용할 수 있습니다.', link: '/space' },
			// { groupID: 2, cate: 'SODAM STORE', title: '소담상회', desc: '소상공인의 디지털 전환을 위한 <strong>소상공인 전용 O2O 플래그십 스토어</strong>입니다.  ', link: '/space' },
		],
		swiperOption: {
			loop: false,
			touchRatio: 0.3, //드래그 금지
			slidesPerView: 3,
			slidesPerGroup: 3,
			spaceBetween: 30,
			breakpoints: {
				200: {
					spaceBetween: 15,
					slidesPerView: 1.1,
					slidesPerGroup: 1,
				},
				360: {
					spaceBetween: 15,
					slidesPerView: 1.2,
					slidesPerGroup: 1,
				},
				450: {
					spaceBetween: 15,
					slidesPerView: 1.4,
					slidesPerGroup: 1,
				},
				500: {
					spaceBetween: 15,
					slidesPerView: 1.7,
					slidesPerGroup: 1,
				},
				620: {
					spaceBetween: 15,
					slidesPerView: 2.1,
					slidesPerGroup: 1,
				},
				700: {
					spaceBetween: 15,
					slidesPerView: 2.5,
					slidesPerGroup: 1,
				},
				801: {
					spaceBetween: 30,
					slidesPerView: 3,
					slidesPerGroup: 3,
				},
			},
			pagination: {
				el: '.fp-light-box .swiper-pagination',
				type: 'fraction',
			},
			autoplay: {
				delay: 5000,
				disableOnInteraction: false,
			},
			navigation: {
				nextEl: '.fp-light-box .swiper-button-next',
				prevEl: '.fp-light-box .swiper-button-prev',
			},
		},
	}),
	computed: {
		...mapGetters('auth', ['session', 'isAuth']),
		...mapGetters('common', ['isMobile', 'returnRoute']),
	},
	created() {},
	mounted() {
		this.$nextTick(() => {
			this.thisSwiper = this.$refs.swiperIndex.$swiper;
		});
		// window resize 이벤트 리스너 추가
		window.addEventListener('resize', this.updateStepIndex);
	},
	beforeDestroy() {
		// 컴포넌트가 파괴되기 전에 이벤트 리스너 제거
		window.removeEventListener('resize', this.updateStepIndex);
	},
	methods: {
		onSwiperChange() {
			this.activeIndex = Number(this.thisSwiper.realIndex);
			this.updateStepIndex();
		},
		gotoSwiper(idx) {
			this.thisSwiper.slideTo(idx);
		},
		// window resize 이벤트에 대한 핸들러
		updateStepIndex() {
			const windowWidth = window.innerWidth;
			if (windowWidth >= 800) {
				switch (this.activeIndex) {
					case 0:
						this.stepIndex = 0;
						break;
					case 3:
						this.stepIndex = 1;
						break;
					case 6:
						this.stepIndex = 2;
						break;
					default:
						this.stepIndex = 0;
						break;
				}
			} else {
				if (this.activeIndex < 3) {
					this.stepIndex = 0;
				} else if (this.activeIndex < 6) {
					this.stepIndex = 1;
				} else {
					this.stepIndex = 2;
				}
			}
		},
	},
};
</script>
