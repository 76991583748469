<template>
	<section class="fullpage fp-facility __fp-light">
		<div class="fp-content container">
			<h2 class="fp-title">소담스퀘어 예약</h2>
			<p v-if="activeIndex < 2" class="fp-subtitle">소상공인 누구나 무료로 스튜디오, 장비 이용 가능!</p>
			<p v-else class="fp-subtitle">소상공인의 이야기와 제품을 담은 공간, 소담상회!</p>

			<div class="fp-sodam-wrap">
				<nav class="page-nav style-orange">
					<div class="container">
						<ul class="nav-list">
							<li v-for="(item, idx) in tabList" :key="idx" class="nav-item" :class="{ 'is-active': activeIndex === idx }">
								<button type="button" class="button" @click.prevent="gotoSwiper(idx)">
									<span class="nav-text">{{ item }}</span>
								</button>
							</li>
						</ul>
					</div>
				</nav>
				<div class="sodam-map-wrap">
					<swiper ref="swiper" class="swiper" :options="swiperOption" @slideChange="onSwiperChange">
						<!-- 소담 스퀘어 -->
						<swiper-slide>
							<div class="fp-map-wrap" @mouseenter="onSwiperOver" @mouseleave="onSwiperOut">
								<div class="flex-l">
									<div class="fp-map">
										<div class="fp-map-bg"><icon name="map" /></div>
										<button type="button" class="fp-map-pin fp-map-loc__seoul is-active" @click="openMap('seoul', $event)">
											<i class="icon"><icon name="icon-pin" /><icon name="icon-pin-on" /></i>
											<span class="fp-map-pin__text">서울</span>
											<em class="fp-map-pin__num">2</em>
										</button>
										<button type="button" class="fp-map-pin fp-map-loc__gangwon" @click="openMap('gangwon', $event)">
											<i class="icon"><icon name="icon-pin" /><icon name="icon-pin-on" /></i>
											<span class="fp-map-pin__text">강원</span>
											<em class="fp-map-pin__num">1</em>
										</button>
										<button type="button" class="fp-map-pin fp-map-loc__jeonju" @click="openMap('jeonju', $event)">
											<i class="icon"><icon name="icon-pin" /><icon name="icon-pin-on" /></i>
											<span class="fp-map-pin__text">전주</span>
											<em class="fp-map-pin__num">1</em>
										</button>
										<button type="button" class="fp-map-pin fp-map-loc__gwangju" @click="openMap('gwangju', $event)">
											<i class="icon"><icon name="icon-pin" /><icon name="icon-pin-on" /></i>
											<span class="fp-map-pin__text">광주</span>
											<em class="fp-map-pin__num">1</em>
										</button>
										<button type="button" class="fp-map-pin fp-map-loc__daegu" @click="openMap('daegu', $event)">
											<i class="icon"><icon name="icon-pin" /><icon name="icon-pin-on" /></i>
											<span class="fp-map-pin__text">대구</span>
											<em class="fp-map-pin__num">1</em>
										</button>
										<button type="button" class="fp-map-pin fp-map-loc__busan" @click="openMap('busan', $event)">
											<i class="icon"><icon name="icon-pin" /><icon name="icon-pin-on" /></i>
											<span class="fp-map-pin__text">부산</span>
											<em class="fp-map-pin__num">1</em>
										</button>
									</div>
								</div>
								<div class="flex-r">
									<!-- 소담스퀘어 서울 -->
									<div id="sodam-seoul" class="fp-map-panel collapse-content is-active">
										<!-- <div class="fp-map-content fp-map-content--small" tabindex="0" @click="openCollapse($event)">
											<div class="fp-map-content__l">
												<img src="@/assets/img/renew/map2_seoul_yeok.jpg" alt="소담스퀘어 in 역삼 사진" />
											</div>
											<div class="fp-map-content__r">
												<div class="fp-map-content__header">
													<strong class="fp-map-content__title is-big">소담스퀘어 in 역삼</strong>
												</div>
												<div class="fp-map-content__body fp-normal-scroll">
													<p class="fp-map-content__desc is-narrow">소상공인이라면 누구나 무료로 스튜디오 및 장비 이용이 가능하며 트렌드를 반영한 홍보 콘텐츠 제작, 라이브 커머스 방송 등 온라인 판로 확대 프로그램을 무료로 지원합니다.</p>
													<img src="@/assets/img/renew/map_process1.png" alt="1.네이버플레이스, 가치삽시다 예약 2.소상공인 확인증 지참 3.스튜디오 시설 이용 및 장비 대여" />
												</div>
												<div class="fp-map-content__footer">
													<div class="buttons">
														<router-link to="/studio?platid=8" class="button-default is-primary is-rounded">시설 예약하기</router-link>
													</div>
												</div>
											</div>
										</div> -->
										<div class="fp-map-content fp-map-content--small" tabindex="0" @click="openCollapse($event)">
											<div class="fp-map-content__l">
												<img src="@/assets/img/renew/map2_seoul_sang.jpg" alt="소담스퀘어 in 상암 사진" />
											</div>
											<div class="fp-map-content__r">
												<div class="fp-map-content__header">
													<strong class="fp-map-content__title is-big">소담스퀘어 in 상암</strong>
												</div>
												<div class="fp-map-content__body fp-normal-scroll">
													<p class="fp-map-content__desc is-narrow">소상공인이라면 누구나 무료로 스튜디오 및 장비 이용이 가능하며 트렌드를 반영한 홍보 콘텐츠 제작, 라이브 커머스 방송 등 온라인 판로 확대 프로그램을 무료로 지원합니다.</p>
													<img src="@/assets/img/renew/map_process1.png" alt="1.네이버플레이스, 가치삽시다 예약 2.소상공인 확인증 지참 3.스튜디오 시설 이용 및 장비 대여" />
												</div>
												<div class="fp-map-content__footer">
													<div class="buttons">
														<!-- <a href="/studio?platid=9" class="button-default is-primary is-rounded">시설예약하기</a> -->
														<router-link to="/studio?platid=9" class="button-default is-primary is-rounded">시설 예약하기</router-link>
														<!-- <router-link to="/space/3" class="button-default is-primary is-rounded">시설 예약하기</router-link> -->
													</div>
												</div>
											</div>
										</div>
										<div class="fp-map-content fp-map-content--small" tabindex="0" @click="openCollapse($event)">
											<div class="fp-map-content__l">
												<img src="@/assets/img/renew/map2_seoul_dang.jpg" alt="소담스퀘어 in 당산 사진" />
											</div>
											<div class="fp-map-content__r">
												<div class="fp-map-content__header">
													<strong class="fp-map-content__title is-big">소담스퀘어 in 당산</strong>
												</div>
												<div class="fp-map-content__body fp-normal-scroll">
													<p class="fp-map-content__desc is-narrow">소상공인이라면 누구나 무료로 스튜디오 및 장비 이용이 가능하며 트렌드를 반영한 홍보 콘텐츠 제작, 라이브 커머스 방송 등 온라인 판로 확대 프로그램을 무료로 지원합니다.</p>
													<img src="@/assets/img/renew/map_process1.png" alt="1.네이버플레이스, 가치삽시다 예약 2.소상공인 확인증 지참 3.스튜디오 시설 이용 및 장비 대여" />
												</div>
												<div class="fp-map-content__footer">
													<div class="buttons">
														<!-- <a href="/studio?platid=10" class="button-default is-primary is-rounded">시설 예약하기</a> -->
														<router-link to="/studio?platid=10" class="button-default is-primary is-rounded">시설 예약하기</router-link>
														<!-- <router-link to="/space/6" class="button-default is-primary is-rounded">시설 예약하기</router-link> -->
													</div>
												</div>
											</div>
										</div>
									</div>
									<!-- //소담스퀘어 서울 -->
									<!-- 소담스퀘어 강원 -->
									<div id="sodam-gangwon" class="fp-map-panel">
										<div class="fp-map-content">
											<div class="fp-map-content__l">
												<img src="@/assets/img/renew/map2_gang.jpg" alt="소담스퀘어 강원 사진" />
											</div>
											<div class="fp-map-content__r">
												<div class="fp-map-content__header">
													<strong class="fp-map-content__title is-big">소담스퀘어 in 강원</strong>
												</div>
												<div class="fp-map-content__body fp-normal-scroll">
													<p class="fp-map-content__desc is-narrow">소상공인 디지털 전환이 시작되는 곳 소담스퀘어 강원은 급변하는 디지털 전환에 어려움을 겪고 있는 소상공인 여러분을 위한 라이브커머스 맞춤형 지원 공간입니다.</p>
													<img src="@/assets/img/renew/map_process1.png" alt="1.네이버플레이스, 가치삽시다 예약 2.소상공인 확인증 지참 3.스튜디오 시설 이용 및 장비 대여" />
												</div>
												<div class="fp-map-content__footer">
													<div class="buttons">
														<router-link to="/studio?platid=15" class="button-default is-primary is-rounded">시설 예약하기</router-link>
														<!-- <router-link to="/space/5" class="button-default is-primary is-rounded">시설 예약하기</router-link> -->
													</div>
												</div>
											</div>
										</div>
									</div>
									<!-- //소담스퀘어 강원 -->
									<!-- 소담스퀘어 전주 -->
									<div id="sodam-jeonju" class="fp-map-panel">
										<div class="fp-map-content">
											<div class="fp-map-content__l">
												<img src="@/assets/img/renew/map2_jeonju.jpg" alt="소담스퀘어 전주 사진" />
											</div>
											<div class="fp-map-content__r">
												<div class="fp-map-content__header">
													<strong class="fp-map-content__title is-big">소담스퀘어 in 전주</strong>
												</div>
												<div class="fp-map-content__body fp-normal-scroll">
													<p class="fp-map-content__desc is-narrow">소상공인 디지털 전환이 시작되는 곳 소담스퀘어 전주는 급변하는 디지털 전환에 어려움을 겪고 있는 소상공인 여러분을 위한 라이브커머스 맞춤형 지원 공간입니다.</p>
													<img src="@/assets/img/renew/map_process1.png" alt="1.네이버플레이스, 가치삽시다 예약 2.소상공인 확인증 지참 3.스튜디오 시설 이용 및 장비 대여" />
												</div>
												<div class="fp-map-content__footer">
													<div class="buttons">
														<router-link to="/studio?platid=13" class="button-default is-primary is-rounded">시설 예약하기</router-link>
														<!-- <router-link to="/space/2" class="button-default is-primary is-rounded">시설 예약하기</router-link> -->
													</div>
												</div>
											</div>
										</div>
									</div>
									<!-- 소담스퀘어 전주 -->
									<!-- 소담스퀘어 광주 -->
									<div id="sodam-gwangju" class="fp-map-panel">
										<div class="fp-map-content">
											<div class="fp-map-content__l">
												<img src="@/assets/img/renew/map2_gwangju.jpg" alt="소담스퀘어 광주 사진" />
											</div>
											<div class="fp-map-content__r">
												<div class="fp-map-content__header">
													<strong class="fp-map-content__title is-big">소담스퀘어 in 광주</strong>
												</div>
												<div class="fp-map-content__body fp-normal-scroll">
													<p class="fp-map-content__desc is-narrow">소상공인 디지털 전환이 시작되는 곳 소담스퀘어 광주는 급변하는 디지털 전환에 어려움을 겪고 있는 소상공인 여러분을 위한 라이브커머스 맞춤형 지원 공간입니다.</p>
													<img src="@/assets/img/renew/map_process1.png" alt="1.네이버플레이스, 가치삽시다 예약 2.소상공인 확인증 지참 3.스튜디오 시설 이용 및 장비 대여" />
												</div>
												<div class="fp-map-content__footer">
													<div class="buttons">
														<router-link to="/studio?platid=14" class="button-default is-primary is-rounded">시설 예약하기</router-link>
														<!-- <router-link to="/space/4" class="button-default is-primary is-rounded">시설 예약하기</router-link> -->
													</div>
												</div>
											</div>
										</div>
									</div>
									<!-- 소담스퀘어 광주 -->
									<!-- 소담스퀘어 대구 -->
									<div id="sodam-daegu" class="fp-map-panel">
										<div class="fp-map-content">
											<div class="fp-map-content__l">
												<img src="@/assets/img/renew/map2_daegu.jpg" alt="소담스퀘어 대구 사진" />
											</div>
											<div class="fp-map-content__r">
												<div class="fp-map-content__header">
													<strong class="fp-map-content__title is-big">소담스퀘어 in 대구</strong>
												</div>
												<div class="fp-map-content__body fp-normal-scroll">
													<p class="fp-map-content__desc is-narrow">소상공인 디지털 전환이 시작되는 곳 소담스퀘어 대구는 급변하는 디지털 전환에 어려움을 겪고 있는 소상공인 여러분을 위한 라이브커머스 맞춤형 지원 공간입니다.</p>
													<img src="@/assets/img/renew/map_process1.png" alt="1.네이버플레이스, 가치삽시다 예약 2.소상공인 확인증 지참 3.스튜디오 시설 이용 및 장비 대여" />
												</div>
												<div class="fp-map-content__footer">
													<div class="buttons">
														<router-link to="/studio?platid=11" class="button-default is-primary is-rounded">시설 예약하기</router-link>
														<!-- <router-link to="/space/7" class="button-default is-primary is-rounded">시설 예약하기</router-link> -->
													</div>
												</div>
											</div>
										</div>
									</div>
									<!-- 소담스퀘어 대구 -->
									<!-- 소담스퀘어 부산 -->
									<div id="sodam-busan" class="fp-map-panel">
										<div class="fp-map-content">
											<div class="fp-map-content__l">
												<img src="@/assets/img/renew/map2_busan.jpg" alt="소담스퀘어 부산 사진" />
											</div>
											<div class="fp-map-content__r">
												<div class="fp-map-content__header">
													<strong class="fp-map-content__title is-big">소담스퀘어 in 부산</strong>
												</div>
												<div class="fp-map-content__body fp-normal-scroll">
													<p class="fp-map-content__desc is-narrow">소상공인 디지털 전환이 시작되는 곳 소담스퀘어 부산은 급변하는 디지털 전환에 어려움을 겪고 있는 소상공인 여러분을 위한 라이브커머스 맞춤형 지원 공간입니다.</p>
													<img src="@/assets/img/renew/map_process1.png" alt="1.네이버플레이스, 가치삽시다 예약 2.소상공인 확인증 지참 3.스튜디오 시설 이용 및 장비 대여" />
												</div>
												<div class="fp-map-content__footer">
													<div class="buttons">
														<router-link to="/studio?platid=12" class="button-default is-primary is-rounded">시설 예약하기</router-link>
														<!-- <router-link to="/space/8" class="button-default is-primary is-rounded">시설 예약하기</router-link> -->
													</div>
												</div>
											</div>
										</div>
									</div>
									<!-- 소담스퀘어 부산 -->
								</div>
							</div>
						</swiper-slide>
						<!-- //소담 스퀘어 -->
						<!-- 라이브 스튜디오 -->
						<swiper-slide>
							<div class="fp-map-wrap" @mouseenter="onSwiperOver" @mouseleave="onSwiperOut">
								<div class="flex-l">
									<div class="fp-map">
										<div class="fp-map-bg"><icon name="map" /></div>
										<button type="button" class="fp-map-pin fp-map-loc__seoul is-active">
											<i class="icon"><icon name="icon-pin" /><icon name="icon-pin-on" /></i>
											<span class="fp-map-pin__text">서울</span>
											<em class="fp-map-pin__num">1</em>
										</button>
									</div>
								</div>
								<div class="flex-r">
									<div class="fp-map-content">
										<div class="fp-map-content__l">
											<img src="@/assets/img/renew/map1.jpg" alt="스튜디오 사진" />
										</div>
										<div class="fp-map-content__r">
											<div class="fp-map-content__body">
												<strong class="fp-map-content__title"> 소상공인 누구나 스튜디오에서 방송 및 제품 촬영을 무료로 이용할 수 있어요 </strong>
												<p class="fp-map-content__desc">라이브 스튜디오는 소상공인이 사진촬영,영상 제작, 라이브 방송, 온라인 커머스 상품 촬영 등 전문화된 장비를 활용하여 다양한 콘텐츠 제작을 지원하는 공간입니다.</p>
												<dl class="fp-map-content__dl">
													<dt>라이브 스튜디오 예약방법</dt>
													<dd>
														<ol>
															<li>날짜, 시간, 이용인원, 연락처, 필요 대여 장비등을 입력하여 신청합니다.</li>
															<li>관리자의 승인 후 라이브 스튜디오 예약이 확정됩니다.</li>
														</ol>
													</dd>
												</dl>
											</div>
											<div class="fp-map-content__footer">
												<div class="buttons">
													<!-- <a href="/studio" class="button-default is-primary is-rounded">신청하기</a> -->
													<router-link to="/studio" class="button-default is-primary is-rounded">신청하기</router-link>
													<!-- <router-link to="/space/9" class="button-default is-primary is-rounded">신청하기</router-link> -->
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</swiper-slide>
						<!-- //라이브 스튜디오 -->
						<!-- 소담상회 -->
						<swiper-slide>
							<div class="fp-map-wrap" @mouseenter="onSwiperOver" @mouseleave="onSwiperOut">
								<div class="flex-l">
									<div class="fp-map">
										<div class="fp-map-bg"><icon name="map" /></div>
										<button type="button" class="fp-map-pin fp-map-loc__seoul is-active">
											<i class="icon"><icon name="icon-pin" /><icon name="icon-pin-on" /></i>
											<span class="fp-map-pin__text">서울</span>
											<em class="fp-map-pin__num">2</em>
										</button>
									</div>
								</div>
								<div class="flex-r">
									<div class="fp-map-content fp-map-content--small">
										<div class="fp-map-content__l">
											<img src="@/assets/img/renew/map3_insa.jpg" alt="소담상회 인사점 사진" />
										</div>
										<div class="fp-map-content__r">
											<div class="fp-map-content__body">
												<strong class="fp-map-content__title is-big">소담상회 인사점</strong>
											</div>
											<div class="fp-map-content__footer">
												<div class="buttons">
													<a href="https://www.idus.com/w/event/regular/ad764b9b-9f5c-4fa1-bfcb-f68f8639d4d4#/" target="_blank" class="button-default is-light is-rounded">사업안내</a>
													<a href="https://map.naver.com/v5/entry/place/1844059951?c=14135876.2947536,4519460.9203111,13,0,0,0,dh&placePath=%2Fhome%3Fentry=plt" target="_blank" class="button-default is-light is-rounded">위치안내</a>
													<a href="https://fanfandaero.kr/portal/preSprtBizPbancDetail.do?sprtBizCd=202403050300&sprtBizTrgtYn=N" target="_blank" class="button-default is-primary is-rounded">신청하기</a>
												</div>
											</div>
										</div>
									</div>
									<div class="fp-map-content fp-map-content--small">
										<div class="fp-map-content__l">
											<img src="@/assets/img/renew/map3_seo.jpg" alt="소담상회 서교점 사진" />
										</div>
										<div class="fp-map-content__r">
											<div class="fp-map-content__body">
												<strong class="fp-map-content__title is-big">소담상회 서교점</strong>
											</div>
											<div class="fp-map-content__footer">
												<div class="buttons">
													<a href="https://www.idus.com/w/event/regular/ad764b9b-9f5c-4fa1-bfcb-f68f8639d4d4#/" target="_blank" class="button-default is-light is-rounded">사업안내</a>
													<a href="https://map.naver.com/v5/entry/place/1358125051?c=14128767.6017546,4516564.6804060,13,0,0,0,dh&placePath=%2Fhome&entry=plt" target="_blank" class="button-default is-light is-rounded">위치안내</a>
													<a href="https://www.iduscraftlab.com/" target="_blank" class="button-default is-primary is-rounded">신청하기</a>
												</div>
											</div>
										</div>
									</div>
									<!-- <div class="fp-map-content fp-map-content--small">
										<div class="fp-map-content__l">
											<img src="@/assets/img/renew/map3_han.jpg" alt="소담상회 한남점 사진" />
										</div>
										<div class="fp-map-content__r">
											<div class="fp-map-content__body">
												<strong class="fp-map-content__title is-big">소담상회 한남점</strong>
											</div>
											<div class="fp-map-content__footer">
												<div class="buttons">
													<a href="https://events.interpark.com/exhibition?exhibitionCode=220330005" target="_blank" class="button-default is-light is-rounded">사업안내</a>
													<a href="https://map.naver.com/v5/entry/place/1232637907?c=14137852.1823193,4514766.5345379,13,0,0,0,dh&placePath=%2Fhome%3Fentry=plt" target="_blank" class="button-default is-light is-rounded">위치안내</a> -->
									<!-- <a href="https://fanfandaero.kr/portal/preSprtBizPbancDetail.do?sprtBizCd=202403050200&sprtBizTrgtYn=N" target="_blank" class="button-default is-primary is-rounded">신청하기</a> -->
									<!-- </div>
											</div>
										</div>
									</div> -->
								</div>
							</div>
						</swiper-slide>
						<!-- //소담상회 -->
					</swiper>
					<!-- 20240516 : 표준 swiper control -->
					<swiper-controls :swiper="thisSwiper" :auto="true"></swiper-controls>
				</div>
			</div>
		</div>
	</section>
</template>

<script>
import SwiperControls from '@/components/common/SwiperControls';
import Icon from '@/components/common/Icon';
export default {
	name: 'MainSodam',
	props: {},
	components: { SwiperControls, Icon },
	computed: {},
	mounted() {
		this.$nextTick(() => {
			this.thisSwiper = this.$refs.swiper.$swiper;
		});
	},
	data: () => ({
		activeIndex: 0,
		thisSwiper: null,
		tabList: ['소담스퀘어', '라이브 스튜디오', '소담상회'],
		swiperOption: {
			loop: false,
			initialSlide: 0,
			autoHeight: true,
			autoplay: {
				delay: 4000,
				disableOnInteraction: false,
			},
			slidesPerView: 1,
			// loopedSlides: 0,
			spaceBetween: 0,
			touchRatio: 0.2,
			navigation: {
				nextEl: '.sodam-map-wrap .swiper-button-next',
				prevEl: '.sodam-map-wrap .swiper-button-prev',
			},

			pagination: {
				el: '.sodam-map-wrap .swiper-pagination',
				type: 'fraction',
			},
		},
	}),
	methods: {
		gotoSwiper(idx) {
			this.thisSwiper.slideTo(idx);
		},
		onSwiperChange() {
			this.activeIndex = this.thisSwiper.activeIndex;

			const element = document.querySelectorAll('.fp-sodam-wrap .page-nav .nav-item')[this.activeIndex];
			if (element) {
				const scrollContainer = document.querySelector('.fp-sodam-wrap');

				const elementRect = element.getBoundingClientRect();
				const elementWidth = elementRect.width;
				const elementLeft = elementRect.left;
				const scrollContainerWidth = scrollContainer.getBoundingClientRect().width;

				// 요소가 중앙에 위치하도록 스크롤 위치를 계산합니다.
				const scrollLeft = elementLeft + elementWidth / 2 - scrollContainerWidth / 2;

				setTimeout(() => {
					element.parentNode.parentNode.scroll({ left: scrollLeft, behavior: 'smooth' });
				}, 100);
			}
		},
		onSwiperOver() {
			this.thisSwiper.autoplay.stop();
		},
		onSwiperOut() {
			this.thisSwiper.autoplay.start();
		},

		// 소담 스퀘어 지역별 보이기
		openMap(target, event) {
			const pinItems = event.target.closest('.fp-map-wrap').querySelectorAll('.fp-map-pin');
			for (let item of pinItems) {
				item.closest('.fp-map-pin').classList.remove('is-active');
			}
			event.target.closest('.fp-map-pin').classList.add('is-active');

			const mapItems = event.target.closest('.fp-map-wrap').querySelectorAll('.fp-map-panel');
			for (let item of mapItems) {
				item.closest('.fp-map-panel').classList.remove('is-active');
			}
			document.getElementById('sodam-' + target).classList.add('is-active');
			setTimeout(() => {
				event.target.closest('.swiper-wrapper').style.height = event.target.closest('.swiper-slide').offsetHeight + 'px';
			}, 100);
		},
		// 소담 스퀘어 서울 지역 collapse accordion 처리
		openCollapse(event) {
			const collapseItems = event.target.closest('.collapse-content').querySelectorAll('.fp-map-content');
			for (let item of collapseItems) {
				item.closest('.fp-map-content').classList.remove('is-collapse');
			}
			event.target.closest('.fp-map-content').classList.add('is-collapse');
			setTimeout(() => {
				event.target.closest('.swiper-wrapper').style.height = event.target.closest('.swiper-slide').offsetHeight + 'px';
			}, 100);
		},
	},
	destroyed() {},
};
</script>
