import { render, staticRenderFns } from "./MainSodam.vue?vue&type=template&id=68ad1364"
import script from "./MainSodam.vue?vue&type=script&lang=js"
export * from "./MainSodam.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports